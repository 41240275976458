import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Alert, Button, Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import ToTop from 'src/Components/ToTop';

import * as Base from 'src/page-styles/base.styled';

import Step1Image from 'src/assets/images/tutorials/step1/pic1.png';
import Step2Image from 'src/assets/images/tutorials/step1/pic2.png';
import Step3Image from 'src/assets/images/tutorials/step1/pic3.png';
import Step4Image from 'src/assets/images/tutorials/step1/pic4.png';

const { Header, Paragraph } = Typography;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function Step1(): JSX.Element {
  return (
    <Layout>
      <ToTop />
      <Container.Dark>
        <Base.MenuGap />
        <Base.Heading>Tutorial 1: Basics</Base.Heading>
        <Base.Text>
          <Header level={3}>Step 1</Header>
          <Base.Image src={Step1Image} />
          <Paragraph>
            The P.I.G.I. main menu is the selection wheel on the bottom left of
            the screen.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 2</Header>
          <Base.Image src={Step2Image} />
          <div style={{ margin: '1rem 0' }}>
            <Alert info>
              Using the mouse wheel will zoom in or out. Shift will speed up
              these movements.
            </Alert>
          </div>
          <div style={{ margin: '1rem 0' }}>
            <Alert info>
              The space bar will enable or disable free roaming mode.
            </Alert>
          </div>
          <Paragraph>
            Use the arrow keys or WASD to move your view up, down, left, and
            right. Q and E will rotate your view clockwise or counter clockwise.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 3</Header>
          <Base.Image src={Step3Image} />
          <Paragraph>
            Hover the mouse over the planets tab on the bottom left of the
            screen to bring it up and select your planet.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 4</Header>
          <Base.Image src={Step4Image} />
          <Paragraph>
            In the case of The Quest For Blue, we will select Earth. What you
            see on your screen is considered your scenario.
          </Paragraph>
          <BtnGroup>
            <Link to="/tutorials/tutorial2" style={{ textDecoration: 'none' }}>
              <Button>Next</Button>
            </Link>
          </BtnGroup>
        </Base.Text>
      </Container.Dark>
    </Layout>
  );
}

export default Step1;
